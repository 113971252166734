import React, { useEffect, useState } from "react"
import {graphql} from 'gatsby'

import SEO from "../components/seo"
import Layout from "../components/Layout"
import SliderMiCorona from "../components/Slider_miCorona"
import {IsMobileDevice} from '../utils/index'
import AgeGate2 from "../components/agegate"
  
const QuieroMiCorona = ({data}) => {
  
  const [isMobile, setIsMobile] = useState(false)
  const [age, setAge] = useState(true);

  useEffect(()=>{
    let local = sessionStorage.getItem('age')
      if (local) setAge(false)
    setIsMobile(IsMobileDevice())
  },[])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Corona",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
          window.location.search.includes("?cmp=")
              ? window.location.search.split("?cmp=")[1].split("&")[0]
              : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Quiero mi Corona",
      pageType: "Product page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.cervezacorona.com.ar/quieromicorona",
      url_campaign: `${
          window.location.search.includes("utm_campaign=")
              ? window.location.search.split("utm_campaign=")[1]
              : ""
      }`,
      Step_number: "" ,
    })
  }, [])

  return (
    !age ? (
      <Layout isMobile={isMobile}>
        <SEO title="Quiero Mi Corona" />
          <SliderMiCorona data={data}/>  
      </Layout>
    ):(
      <AgeGate2 path="/quieromicorona"/>
    )
  )
}

export const query = graphql`
    query mi_corona {
       allBeers(sort: {fields: index}) {
            nodes {
              id
              img
              index
              link
              text
              title
              title2
              image {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                  }
                }
              }
            }
          }
      allFile(filter: { sourceInstanceName: {eq:"page_quieroMiCorona"}}) {
          edges {
              node {
              base
              childImageSharp {
                  fluid {  
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
              }
      }
      }
      allMarkdownRemark(filter: {frontmatter: {id: {eq: "page_mi_corona"}}}) {
          edges {
            node {
              frontmatter {
                slide
                path
                title
                title_2
                paragraph
                link
                button
                typeLeftLogo
                typeRightLogo
                background_mobile
                typeArrowLogo_mobile
                typeRightLogo_mobile
                typeCenterLogo_mobile
              }
            }
          }
        }
  }`;

export default QuieroMiCorona

