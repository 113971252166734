import React, { Fragment, useEffect, useState } from "react"

import { InformationToImages_miCorona } from "../utils/index"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderSlick from "react-slick";

import "../styles/Slider_miCorona.css"
import "../styles/Background_miCorona.css"

import Footer from './Footer'
import Div100vh from 'react-div-100vh'

import lata from '../../assets/images/page_quieroMiCorona/lata.png'
import porron from '../../assets/images/page_quieroMiCorona/porron.png'
import botella from '../../assets/images/page_quieroMiCorona/botella.png'

import { IsMobileDevice } from '../utils'
import { FooterHome } from "./FooterHome/FooterHome"


const Slider_miCorona = ({ data }) => {

  const [images, setImages] = useState([])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // console.log("DATA",data)
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    setImages(InformationToImages_miCorona(data))
  }, [])

  let items = []
  data.allBeers.nodes.map(function(node){
    items.push(node)
  })

  const customeSlider = React.useRef();

  const handlerArrow = (e, direction) => {
    e.preventDefault();
    if (direction == 'next') {
      customeSlider.current.slickNext()
    } else {
      customeSlider.current.slickPrev()
    }
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: false,
    verticalSwiping: true,
  };

  const settings2 = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: false,
    verticalSwiping: true,
  };

  useEffect(() => {
    if (customeSlider.current) {
      let slickListDiv = document.getElementsByClassName('slick-list')[0]
      slickListDiv.addEventListener('wheel', event => {
        event.preventDefault()
        if (customeSlider.current) {
          event.deltaY > 0 ? customeSlider.current.slickNext() : customeSlider.current.slickPrev()
        }
      })
    }
  }, [])

  return (

      <Div100vh  style={{ overflow: 'hidden' }}>

        <SliderSlick {...settings} verticalSwiping={!isMobile} ref={customeSlider} className="carousel_miCorona">

          {items.map((item, index) => {

            return (

              <div key={index} className="container-fluid m-0 p-0" id='element'>

                <Div100vh className="row miCorona-content">

                  <div className="col-lg-6 d-flex align-items-center" id="info">

                    <div className="miCorona-info">

                      <h1 className="miCorona-title">{item.title}
                        <h2 className="miCorona-size">ml</h2>
                      </h1>
                      <h2 className="miCorona-subtitle">{item.title2}</h2>

                      <span className="borderLeft_miCorona"></span>

                      <div className="col-md-10 p-0">
                        <p className="miCorona-paragraph">{item.text}</p>
                      </div>

                      <div>
                        <a target={'_blank'} href={item.link} className="button btn-bt_miCorona">
                          <span>Comprar</span>
                        </a>
                      </div>

                    </div>

                  </div>

                  <div className="col-lg-6 d-flex align-items-center justify-content-center" id="background">
                    <img src={item.img} alt="lata" className="img_miCorona_lata" />
                  </div>

                </Div100vh>

              </div>

            )

          })}

        </SliderSlick>
        {/* footer   */}
        <div className="miCoronaContentFooter">
          <Footer handlerArrow={handlerArrow} />
        </div>
        <FooterHome/>
      </Div100vh>




  )
}

export default Slider_miCorona